import { getFileAsBase64 } from 'utilities/images.utils';
import { fileToBase64, getFileSizeMB } from 'utilities/common/file.utils';
import { v4 as uuidv4 } from 'uuid';
import { AttachmentInterface } from 'models/attachments/interfaces/AttachmentInterface';
import { dumpitDatabase } from 'store/rxdb/dumpitDatabase';
import { getCurrentUTCDateFormatted } from 'utilities/date.utils';
import { IDraftAttachment } from 'store/dumpDraft.store';
import { toast } from 'react-toastify';
import { MAX_ATTACHMENTS_REACHED_MESSAGE, MAX_FILE_SIZE } from 'constants/attachment.constants';
import { captureMessage } from '@sentry/react';
import { getAudioDuration } from 'utilities/audio.utils';

export const createDumpGenericAttachment = async (dumpId: string, file: File, userId: string) => {
  const base64FileData = await fileToBase64(file);

  const id = uuidv4().toString();
  const newAttachment: AttachmentInterface = {
    id,
    filename: file.name,
    duration: 0,
    dump_id: dumpId,
    user_id: userId,
    created_at: getCurrentUTCDateFormatted(),
    updated_at: getCurrentUTCDateFormatted(),
    is_synced: false,
    file_data: base64FileData,
  };

  await dumpitDatabase.attachments.insert(newAttachment);
};

export const createDumpAudioAttachment = async (dumpId: string, file: File, userId: string) => {
  const id = uuidv4().toString();

  const fileExtension = file.name.split('.').pop();
  const newFileName = `${id}.${fileExtension}`;

  const renamedFile = new File([file], newFileName, { type: file.type });

  const duration = await getAudioDuration(renamedFile);

  const base64FileData = await fileToBase64(renamedFile);

  const newAttachment: AttachmentInterface = {
    id,
    filename: renamedFile.name,
    duration,
    dump_id: dumpId,
    user_id: userId,
    created_at: getCurrentUTCDateFormatted(),
    updated_at: getCurrentUTCDateFormatted(),
    is_synced: false,
    file_data: base64FileData,
  };

  await dumpitDatabase.attachments.insert(newAttachment);
};

export const getDraftAttachmentsFromFiles = async (files: File[]) => {
  const newAttachments: IDraftAttachment[] = [];
  await Promise.all(
    files.map(async (file) => {
      const src = await getFileAsBase64(file);
      newAttachments.push({
        src,
        file: file,
      });
    }),
  );

  return newAttachments;
};

export const handleFilesRejected = (data: any) => {
  if (data.errors?.[0]?.name === 'FileAmountLimitError') {
    toast.error(MAX_ATTACHMENTS_REACHED_MESSAGE);
    captureMessage(MAX_ATTACHMENTS_REACHED_MESSAGE);
  }
  if (data.errors?.[0]?.name === 'FileSizeError') {
    toast.error(`You can only upload attachments up to ${getFileSizeMB(MAX_FILE_SIZE)}Mb in size`);
    captureMessage(`You can only upload attachments up to ${getFileSizeMB(MAX_FILE_SIZE)}Mb in size`);
  }
  if (data.errors?.[0]?.name === 'FileTypeError') {
    toast.error(`File type is not allowed`);
    captureMessage(`File type is not allowed`);
  }
};
