import { getDumpById } from './rxDb/RxDbDumpService';
import { getCurrentUTCDateFormatted } from 'utilities/date.utils';
import { pushUnsyncedDumps } from './DumpSyncService';

export async function deleteDump(dumpId: string) {
  const existingDump = await getDumpById(dumpId);
  if (existingDump && existingDump.asDump()) {
    try {
      await existingDump.getLatest().update({
        $set: {
          is_synced: false,
          is_to_be_deleted: true,
          updated_at: getCurrentUTCDateFormatted(),
        },
      });

      await pushUnsyncedDumps();
    } catch (e) {
      await existingDump.getLatest().update({
        $set: {
          is_synced: false,
          updated_at: getCurrentUTCDateFormatted(),
        },
      });
    }
  }
}

export async function pinDump(dumpId: string, isPinned: boolean) {
  const existingDump = await getDumpById(dumpId);
  if (existingDump && existingDump.asDump()) {
    try {
      await existingDump.getLatest().update({
        $set: {
          is_pinned: isPinned,
          updated_at: getCurrentUTCDateFormatted(),
          is_synced: false,
        },
      });

      await pushUnsyncedDumps();
    } catch (e) {
      await existingDump.getLatest().update({
        $set: {
          is_synced: false,
          updated_at: getCurrentUTCDateFormatted(),
        },
      });
    }
  }
}
