import React, { useState } from 'react';

import { Button, Tooltip } from 'components/common/ComponentLibrary';
import {
  CameraIcon,
  MicrophoneIcon,
  PaperClipIcon,
  UserPlusIcon,
  XMarkIcon,
  DocumentIcon,
} from '@heroicons/react/24/outline';
import useNetworkStatus from 'hooks/application/useNetworkStatus';
import { useHotkeys } from 'react-hotkeys-hook';

interface IDumpActions {
  onImageUpload?: () => void;
  onFileUpload?: () => void;
  onRecord?: () => void;
}
const DumpActions = ({ onImageUpload, onFileUpload, onRecord }: IDumpActions) => {
  const { isOnline } = useNetworkStatus();
  const [targetIcon, setTargetIcon] = useState(<PaperClipIcon className='w-8' />);

  useHotkeys('meta+i', () => onImageUpload && onImageUpload(), {
    enableOnFormTags: true,
    preventDefault: true,
  });

  useHotkeys('meta+f', () => onFileUpload && onFileUpload(), {
    enableOnFormTags: true,
    preventDefault: true,
  });

  return (
    <Tooltip
      width='full'
      size='none'
      spacing='none'
      target={
        <Button theme='bare' width='none' size='l'>
          <Button.Icon icon={targetIcon} />
        </Button>
      }
      isInteractive={true}
      isPlain={true}
      scope='local'
      trigger='click'
      placement='top'
      onShow={() => setTargetIcon(<XMarkIcon className='w-8' />)}
      onHidden={() => setTargetIcon(<PaperClipIcon className='w-8' />)}
    >
      <div className='flex flex-1 flex-col items-center justify-end gap-2 w-full bg-white dark:bg-paperdark p-2 rounded-2xl'>
        {onFileUpload && (
          <Button width='none' size='m' theme='bare' onClick={onFileUpload} status={!isOnline ? 'disabled' : ''}>
            <Button.Icon icon={<DocumentIcon className='w-7' />} />
          </Button>
        )}
        {onImageUpload && (
          <Button width='none' size='m' theme='bare' onClick={onImageUpload} status={!isOnline ? 'disabled' : ''}>
            <Button.Icon icon={<CameraIcon className='w-7' />} />
          </Button>
        )}
        {onRecord && (
          <Button width='none' size='m' theme='bare' onClick={onRecord} status={!isOnline ? 'disabled' : ''}>
            <Button.Icon icon={<MicrophoneIcon className='w-7' />} />
          </Button>
        )}
      </div>
    </Tooltip>
  );
};

export default DumpActions;
