import { useEffect } from 'react';
import { validateFile } from 'utilities/common/file.utils';
import { MAX_FILE_SIZE } from 'constants/attachment.constants';
import { ALLOWED_FILE_EXTENSIONS } from 'constants/file.constants';

interface FileRejection {
  errors: { name: string }[];
}

interface IUseFilePaste {
  onFileSuccessfullyPasted: (file: File) => Promise<void> | void;
  onFileRejected: (errors: FileRejection) => void;
}

const generateRandomFileName = (originalFile: File): File => {
  const extension = originalFile.name.split('.').pop() || '';
  const baseName = originalFile.name.split('.')[0];
  const timestamp = Date.now();
  const randomString = Math.random().toString(36).substring(2, 15); // Increased length for more uniqueness
  const newFileName = `${baseName}_${timestamp}_${randomString}.${extension}`;

  return new File([originalFile], newFileName, { type: originalFile.type });
};

export const useFilePaste = ({ onFileSuccessfullyPasted, onFileRejected }: IUseFilePaste): void => {
  useEffect(() => {
    const pasteEventListener = async (e: ClipboardEvent) => {
      if (!e.clipboardData) return;

      // Process each item one at a time
      for (const item of Array.from(e.clipboardData.items)) {
        if (/^image\//.test(item.type)) {
          const file = item.getAsFile();
          if (!file) continue;

          const validatedFileError = validateFile(file, {
            maxSize: MAX_FILE_SIZE,
            allowedTypes: ALLOWED_FILE_EXTENSIONS,
          });

          if (validatedFileError) {
            onFileRejected({ errors: [{ name: validatedFileError.name }] });
            continue;
          }

          // Generate a unique file for each paste
          const fileWithRandomName = generateRandomFileName(file);
          await onFileSuccessfullyPasted(fileWithRandomName);
        }
      }
    };

    window.addEventListener('paste', pasteEventListener);

    return () => {
      window.removeEventListener('paste', pasteEventListener);
    };
  }, [onFileSuccessfullyPasted, onFileRejected]);
};
