import React from 'react';
import BackdropComponent from 'components/common/dialog/Backdrop.component';
import { Heading, Text } from 'components/common/ComponentLibrary';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import { isWindows } from 'react-device-detect';

const Key = ({ children }: any) => {
  return <>{children}</>;
};

const KeyboardShortcutsModal = ({ onDismiss }: { onDismiss: () => void }) => (
  <BackdropComponent dismiss={onDismiss}>
    <div className='w-full p-6'>
      <Heading size='xl' textAlign='left' spacing='l'>
        Keyboard shortcuts
      </Heading>

      <div className='flex gap-14'>
        <div className='flex flex-col gap-5'>
          <div className='flex flex-col gap-1'>
            <Text size='l' fontWeight='bold'>
              Attach an image or file to a dump
            </Text>
            <div className='flex gap-5'>
              <Key>
                <Text color='gray-400' fontFamily='mono'>
                  {isWindows ? 'windows' : 'command'}+i, {isWindows ? 'windows' : 'command'}+f
                </Text>
              </Key>
            </div>
          </div>

          <div className='flex flex-col gap-1'>
            <Text size='l' fontWeight='bold'>
              Share Dump with another user
            </Text>
            <div className='flex gap-5'>
              <Key>
                <Text color='gray-400' fontFamily='mono'>
                  {isWindows ? 'windows' : 'command'}+c
                </Text>
              </Key>
            </div>
          </div>

          <div className='flex flex-col gap-1'>
            <Text size='l' fontWeight='bold'>
              Submit or update a Dump
            </Text>
            <div className='flex gap-5'>
              <Key>
                <Text color='gray-400' fontFamily='mono'>
                  {isWindows ? 'windows' : 'command'}+enter
                </Text>
              </Key>
            </div>
          </div>
        </div>
        <div className='flex flex-col gap-5'>
          <div className='flex flex-col gap-2'>
            <Text size='l' fontWeight='bold'>
              Navigate between screens
            </Text>
            <div className='flex gap-3'>
              <Key>
                <ArrowLeftIcon className='w-5 font-mono text-zinc-500 dark:text-zinc-400' />
              </Key>
              <Key>
                <ArrowRightIcon className='w-5 font-mono text-zinc-500 dark:text-zinc-400' />
              </Key>
            </div>
          </div>

          <div className='flex flex-col gap-1'>
            <Text size='l' fontWeight='bold'>
              Search your Dumps
            </Text>
            <div className='flex gap-5'>
              <Key>
                <Text color='gray-400' fontFamily='mono'>
                  {isWindows ? 'windows' : 'command'}+k
                </Text>
              </Key>
            </div>
          </div>

          <div className='flex flex-col gap-1'>
            <Text size='l' fontWeight='bold'>
              Close an open modal/dialog
            </Text>
            <Key>
              <Text color='gray-400' fontFamily='mono'>
                escape
              </Text>
            </Key>
          </div>

          <div className='flex flex-col gap-1'>
            <Text size='l' fontWeight='bold'>
              Keyboard shortcuts
            </Text>
            <div className='flex gap-5'>
              <Key>
                <Text color='gray-400' fontFamily='mono'>
                  {isWindows ? 'windows' : 'command'}+/
                </Text>
              </Key>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BackdropComponent>
);

export default KeyboardShortcutsModal;
