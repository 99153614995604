import { getAllUnsyncedDumps, markDumpSynced, upsertDump } from './rxDb/RxDbDumpService';
import { captureException, captureMessage } from '@sentry/react';
import supabase from 'store/supabase/supabase';
import { DumpInterface } from '../interfaces/DumpInterface';
import {
  getLocalStorageLastSyncedDumps,
  setLocalStorageLastSyncedDumps,
} from 'models/application/services/LocalStorageService';
import { getCurrentProfileId } from 'models/profiles/services/ProfileService';
import { SupabaseDumpService } from './supabase/SupabaseDumpService';

export async function pushUnsyncedDumps(): Promise<boolean> {
  const unsyncedDumps = await getAllUnsyncedDumps();
  if (unsyncedDumps && unsyncedDumps.length > 0) {
    const currentProfileId = await getCurrentProfileId();
    if (!currentProfileId) {
      return false;
    }

    const supabaseDumpService = new SupabaseDumpService(supabase);
    await Promise.all(
      unsyncedDumps.map(async (dump) => {
        try {
          const asDump = { ...dump.asDump(), _deleted: dump.asDump().is_to_be_deleted };
          delete asDump.is_synced;
          delete asDump.is_to_be_deleted;
          delete asDump.tags;
          const result = await supabaseDumpService.upsert(asDump);
          if (!result) {
            return;
          }

          await markDumpSynced(dump.id, {});
        } catch (e) {
          captureMessage(`pushUnsyncedDumps: userId[${currentProfileId}], dumpId[${dump.id}].`);
          captureException(e);
        }
      }),
    );
  }

  return true;
}

export async function pullLatestDumps(): Promise<boolean> {
  try {
    const currentProfileId = await getCurrentProfileId();
    if (!currentProfileId) {
      return false;
    }

    const supabaseDumpService = new SupabaseDumpService(supabase);

    const dumps = await supabaseDumpService.getAll(getLocalStorageLastSyncedDumps());
    if (!dumps) {
      return false;
    }

    await Promise.all(
      dumps.map(async (_dump) => {
        const dump: DumpInterface = {
          id: _dump.id,
          text: _dump?.text || '',
          user_id: _dump.user_id,
          created_at: _dump.created_at,
          updated_at: _dump.updated_at,
          last_edited_at: _dump.last_edited_at,
          is_synced: true,
        };
        dump.is_pinned = _dump.is_pinned;
        if (_dump?._deleted) {
          dump._deleted = _dump._deleted;
        }

        await upsertDump(dump);

        setLocalStorageLastSyncedDumps(dump.updated_at);
      }),
    );

    return true;
  } catch (error) {
    captureException(error);
    return false;
  }
}
